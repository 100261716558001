import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardWithImageLink } from '../../../components/Card';
import { ButtonOutbound, ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import { OutboundLink } from '../../../components/StyledLink';
import { CallToAction } from '../../../components/CallToAction';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Text } from '../../../components/Text';
import { Box, ProjectBoxDisabled, ProjectBox } from '../../../components/Box';
import ImageIcon from '../../../components/ImageIcon';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Design Challenges" mdxType="SEO" />
    <IntroProject projectName="Design Challenges" projectDescription="Weekly design challenges to help learn, share, and connect with designers in Tampa" projectCaption="A way for me and other designers to design in the open and show a more transparent process" projectYear="2020" projectImage="tbd-preview" mdxType="IntroProject" />
    <Section section="Rules" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Overview</h5>
          <p>Starting in 2020, I thought it would be great to begin quick design challenges to not only help me explore new ways of designing, but also serve as a way to give a more in-depth, transparent look into the design process for designers who were just starting out or who are more senior in their career.</p>
          <p>One of my goals in 2020 was to <strong>design in the open</strong> and I thought a project like this would be a great vehicle for that. So I dug through my inbox and put together a quick Gatsby website so others could keep track of these weekly challenges as well.</p>
          <ProjectBox image="tbd-dc-example-icon" title="Design Challenges" description="Weekly design challenges to help you level up" link="https://design-challenges-2020.netlify.app/" mdxType="ProjectBox" />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.16666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVQ4y51UW28SQRRe2AvssrLA7hKKGwRiSOuLGy0hbUMsSJpoE1supRJj4oPRB3+k2NZo2lpYItYgJj714ovFS9/s58zQ0lC8EB++nNk953zznTNnhtv/fgCKgx+H6Ox10PzgoPWxBYei22LfTtdhttl12D9n4HfQ/tSm+Sc0n1qOku1928eXnz08evwQkiTCDIcR0kPQdR1hsqbWMA0YhsHW1EfXqqrCvmHT/FHC3slX3CstgeO4IeRzedTu11Aql1GuVCAIwpDfill9wuMLhEeEsFQtsSCa5HK5wLk4TGduorJaxkqpgvnZLNyci8W4eTeziWTi94RUYXGlyIJ4nmdW5hUEBQMaH4InokFMBOG5HGA+tiGx8UR8HMJ+WabXxFw8g+nodVhzV5GqpaHPxP+HkB/p5UWMR1gtDnqYtGKwLIucqgHTDMMf0AgCkBUZsiwPNh2LkO5+bWoSz54+QSGXw+KduygUCkin04NRkiSpT5gco2Q32T0UDOL2rSwmdBMh1Q+fzwdFURi8RCV/Oj5j9ZCWrGkaG1yRKOEFnpVIQX2yIP27h0es5P4cUgKvVyaq1D5UlSn0kt4Fidp8dArimcK/zeFyZXnQQ78iQlM9ZJDPT/ZMpcif35bYldifFPZQfVBlQR7So4XMLFYL88hmZ7C0uADbtslpm4hGo4hMRKBeUllsajI1epcpDo8/o9Fp4vlmHfXtNbTaDey+b2Bzd4e8Pm+x9W4b6zsb2Gi8ZFh7s4761gu8ar0eem1+AcpfmVFRmflOAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Website preview",
          "title": "Website preview",
          "src": "/static/9aba1dda2c739d4cec2fd90e09e82133/d9199/tbd-1.png",
          "srcSet": ["/static/9aba1dda2c739d4cec2fd90e09e82133/8ff5a/tbd-1.png 240w", "/static/9aba1dda2c739d4cec2fd90e09e82133/e85cb/tbd-1.png 480w", "/static/9aba1dda2c739d4cec2fd90e09e82133/d9199/tbd-1.png 960w", "/static/9aba1dda2c739d4cec2fd90e09e82133/07a9c/tbd-1.png 1440w", "/static/9aba1dda2c739d4cec2fd90e09e82133/29114/tbd-1.png 1920w", "/static/9aba1dda2c739d4cec2fd90e09e82133/88463/tbd-1.png 3656w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Background" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Background 📋</h5>
          <IconCard cardTitle="Specifications" cardText="A quick Web Design/Development project to help designers keep track of design challenges" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Tools" cardText="React/Gatsby, Figma, Styled Components, Notion" mdxType="IconCard">
            <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
          </IconCard>
          <p>While the Daily UI challenges are great for leveling up some visual/interaction design skills, it could be tedious to work through while balancing work, life, etc. So, we decided to break design challenges up into weekly segments.</p>
          <p>Use these challenges to set goals, learn something new, flex some muscles that haven't been used, help beginners get a behind the scenes view of design, or just do them because it may be fun.</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <Section section="Rules" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Rules ☑️</h5>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><small>Doesn’t have to be an interactive prototype, but could be cool, even if it’s basic</small></p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><small>Spice it up every now and then, use animation to show off some sick little IxD</small></p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><small>Make it weird, make it pretty, make it useful, make it however you'd like</small></p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><small>SHARE your work, even if it's not pretty, or functional, or...</small></p>
          </Box>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>A few of my submissions</h5>
          <p>I've been trying my best to keep up with every challenge, and as of Q1 2020, I've only missed 1 week. Here are a handful of designs I've put together from these challenges.</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Week 17 - Music Player</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOElEQVQ4y4WU/U+TVxTH+9+4uECrvBfcCkVa3pZUNFKVZb4sc4vGaIyJiVuMisgyYhY16laIwPjBBZAsukwjDUInrZRuvClbgjEyeUcRlSql/ezc25bVRdMnuTl5nufez/l+77nnGvxLCySOvlfPCLxexPt8lq7Jx3jmJlZH98w/+n9/8LmOCesi8Wh4F6x7epyqPbuxl5SQX1hIYVERG+02eS/myKkT+GPABOi7gfdePuXP0CtujA5RbC9mk8NBkbWAgo8/Iic7m6zMTKySoGfmiU6cFKgVvnnBnalxKiu3sMNZSovrLI3na7HbrOTkmLEUFHDr4V/8sfySPhGQFNgvme8+nWLn7s+o2FrK6epj/NTg4pMyG+npGeTm5tIW8DEQDmpHSYH+4AK+xTm+2Pclh/bvYu++XWzfWYm92EpmZhZZGRk0uW8xxBvuvZiP234/MF6YvQcPsGWzg81bK3BItElRsmQf1xtNXPylnRFC+ASYVKGyMbCyxMFvvqbCsYmqqk9xVjopLyuTPcwhZe2HfNd8hfuEtZOkChVwMPKao9/WsD7VSL7FQrYoS5cKm/NyMaakcPz896sKkwLVpBFWOHXpAuvS0rCUlbDBtpHsfIsA8zAZjRyuPilJo0V5LzB+pnyL82JnhbMtTaQLsKS8nHyrFbPYNZvNpJnW8dWRw9FjE9tzGZE42JDwMWpZFA6zzA/XO7S9PKXKZGLNB2swijpTaio7Pt/z/0JG4lED/Ql9qWyoI9HcdZttTidnamqoq6vD5XJx9erPeHt7uXbjOj3S2/3/dcvblvvimQSmKqfstHi66HS7Uc9SMEhoOUT8CQwM0Dn+cHXPRcTbCpVNBep9NoNndkLfNI2i8Npvv8puwko4TFhGKBSFjo2N0TEY0DDvwoxaG1HOFNigPvoE0Cs/fp+f4o7cND2zT7jS3Ul9fT3LS0H+fjBKv9+vFbe2tXGpuYmb0s/ehVmdXANjSqNAUeeNQVUfK+Dt8TFqG37k9IVzVF++SG1jA+c6Wql336R9OEDX1GM9T82XdRHFEFbEoKUqegysrcfUeuYncU8+0gXQQwCeWFSXrWduUoDTq0BhRP4FSvCJdwxYDD8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Week 17",
          "title": "Week 17",
          "src": "/static/3e1ce761ab28070727954e56680bf4f7/d9199/week-17.png",
          "srcSet": ["/static/3e1ce761ab28070727954e56680bf4f7/8ff5a/week-17.png 240w", "/static/3e1ce761ab28070727954e56680bf4f7/e85cb/week-17.png 480w", "/static/3e1ce761ab28070727954e56680bf4f7/d9199/week-17.png 960w", "/static/3e1ce761ab28070727954e56680bf4f7/07a9c/week-17.png 1440w", "/static/3e1ce761ab28070727954e56680bf4f7/29114/week-17.png 1920w", "/static/3e1ce761ab28070727954e56680bf4f7/89587/week-17.png 2624w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Week 16 - User Profile</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC/UlEQVQ4y4WUWUhUURiAi96CstKkzVxSp5vjNFpjaYtJm41mRSVttr200UoPafQSUZrtkpm0WJkj2U721kObGWpBRfQQBJWQOjbO2MydaZavc8/AUDbUgQPn3Mv97vf//zl/v4VXIf9KgIJrkFsDs6o8mMWzPLGfd9FPTrWXglow1wRYehMWWQi9Xyb2+bXB74Mc6BfcBMi9DEUVr9lTbmFi/naUeZswbzrM3opGZp9qp6gBtpbdpvrhex68cXCzxcq+ivtsu/qJhdc1mF9yJHCx+EtOlZtdR+uw1NfisHXR09WOT3Xg7P7K3EMv2XPPTcbULJRUI3plAgaDkeiIQWwsOcfKu7DgkvdPYHalk+LKRp6+eIG1x4HN3os2bN+t5Ja+Yscdley5ZlIzZqLoJ5OgMxIREUVRSXUYoFCeXuGgvO4ZAY+Tz1/a6eyySmBPdyfzj7Sx865KZuYMDGlTSTdNJ2m8kaFDIln7L+Cx+mYJUd0ePJ6fcv3d2iGBOwRQMxsZk8SYWB1jx6UQOWx4CGgOb/gUvC5sImSn0xUM+TfD9HQTiToDuglG4hJT/m+oAT1OO9ZuG25hKYHCMLe0TebQZMoUlpMkNC5RT1SkMCw+Hx44TQCP1z8XCB9Ol4qqqjJsa+e3UFFSU9OITVCIF3ZJShojR4wJFcUcHtgkgF7svU4I+HD5oflDB3llQUODIZ34JL0EjtJyqc9hw0ELK27/I4decfZ6fwTz99MXoLX1tchhqwRmZGSSrBiJidMxOjaZ5BQTW0tvUHhLAC/7wlW5iYDXjcvtFTA/Hq+Pd2/eCmAw5LRJU4gVuYuJV+RUshax/cQDChv6AJdoIZ9xcKr+CX2Hx94hb8pucVOS4+MYPGgI0cNHEB09ioED+rO++OzfRSnQ7rJoDCtPtnC87rE8j+WWJk7eaObAhUfMOd3OWmGx+UgDq/edp2j/RdaIua6kii01H/+8y327TZYw1Wy1ImWdsTOzUg11m+UiV6vuIY3kFGsNllfjD3WbX8PqQDEFwiN9AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Week 16",
          "title": "Week 16",
          "src": "/static/714c000e4b3e51fe43c278ef1cd3a02a/d9199/week-16.png",
          "srcSet": ["/static/714c000e4b3e51fe43c278ef1cd3a02a/8ff5a/week-16.png 240w", "/static/714c000e4b3e51fe43c278ef1cd3a02a/e85cb/week-16.png 480w", "/static/714c000e4b3e51fe43c278ef1cd3a02a/d9199/week-16.png 960w", "/static/714c000e4b3e51fe43c278ef1cd3a02a/07a9c/week-16.png 1440w", "/static/714c000e4b3e51fe43c278ef1cd3a02a/29114/week-16.png 1920w", "/static/714c000e4b3e51fe43c278ef1cd3a02a/e3815/week-16.png 2730w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Week 15 - Menu</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXUlEQVQ4y42Uy4raUBjHc3MMM7YUK4riwivirbrQheBl1MnFRBMTLVroC5QyC6HSLlvoAxT6An2ALgrddNvVQKFP0HUXhXZbBjN6+j+pETplyBw4fvFcft//+39Rhrk28vn84XkwGASZeIK7fsYwTeZWo1gsMrZt8/RZVdVzSZJ+TmX5i6nInxRJeq8oyjPvrKZp/sBSqcQsl0vBVTGZvJlMJuQp5kvbJueWRSRZJrqqpug+4OytVE6nUxcIpS+ggqiKcmkZhmNomnN2draVZblL96GeQ0J/4Hw+d4GLxeLJaDQiUOLYs9lO0/UrPBMAH9N9RN4XVqvVmNls5h58iAEfCaBbKNmNx+ONrusEKp/TfURhvV77K7Qsy+2qYRingBAkIBSI9Q21AJ1/S/e73S6PBP5AXHaBOPwA04GntMwdPHOoBcPh8MMeyGLNH9jr9dzudTqdJBT9pr7h4g6gK0Sq8CsSuUmxdjOoUCi4ESq8eAcXf6F0qnC77zAF/gD4ZP/i/w/i+b/NEkWRyWazjOfLarUKQt03eEdM09wiwQ5Q0u/3LwFM7zvtVsNx3M1KCSGHCOAF9Q2lb9BxBwAHZW4BPd37yHnnDyMcDnsK2UQiwUFlIBqNiihHBOAz3ku307TDVCH1ET4/onfK5XIAgf2nWhaDqo5EIgL+GI5SqdRxJpO5F4vF7jYajVftdvt7q9W6aDabH/H9Xb1ef12pVHJILqbT6WAymRQgACye8+D0g4UPnCAIXCgU4tGgQC6XC2Kdmh/DvI8ZwnR/RfF4/BiwE0yxWq0e4QcheMA/Au3EyNRlr3wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Week 15",
          "title": "Week 15",
          "src": "/static/1deefe454cbc7d59c71d019f53b48551/d9199/week-15.png",
          "srcSet": ["/static/1deefe454cbc7d59c71d019f53b48551/8ff5a/week-15.png 240w", "/static/1deefe454cbc7d59c71d019f53b48551/e85cb/week-15.png 480w", "/static/1deefe454cbc7d59c71d019f53b48551/d9199/week-15.png 960w", "/static/1deefe454cbc7d59c71d019f53b48551/07a9c/week-15.png 1440w", "/static/1deefe454cbc7d59c71d019f53b48551/29007/week-15.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Week 13 - Icons</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAACeElEQVQ4y22UW1MaQRCF+RWmgpSiJS7srlzCHeWiiQY1anwwSVX+vy+CGleYvfZJ9+ysYCpsnZoBar86fXp6ciqI4IeiGH6wgApCKL2PIP9FCTCbP6HsdPChYGGz6LzXtoP8tkOylzUnIGVeVkGcgo0y4Hz2CNdpY6NQRsGA8tu2lobuCNCV/Xtgtl+a70vfOHx8xv6aQwEV9lytzaJAbZLfrFKZclnJAUv5gV6jOEHMkjUh4EGAdhu75Taq7S84YLntE9jNCezGGM6nY9qze9jaqaQOlyqA97rAYukj4AxXH+InBZbsFir1EYand+gf36Izukat+1Wr0b+gcm2Ij1sG6L0u8fT8wmAGhimQiFIZ4F6lyU7GmEx/ojf5jtbRN3Z6imrnDLXelKzqEUfxH+DrQiGK4hXUAC27iXprjPH0FwNvMDi5RfPwQgPrDCzXh9wcm3JpA0L4nJ+ARG/utBg4lwxbsKqH6LK75uCCYZcC0iXX++camC8KkEtcKIUXTzJU2mWSJMYdP9yVBz6HWYajsx86Qym70T9/c6hLzjKUZghQXEqnFUP/zbBUyYB3aA+v0B1fs27YIWfYfZdhol09//F4OtY7rENcA0pTJvh8+VuD3srltTGQLo8yoILHjXhiYFoyZxmnWYasmEueGYf7BwOGXaHR4zIZZjfWzqHTkwPPB9ufEbukIIp5jUmFEfFh1+JJIT7bxECynA5tbO6TnDWRvCyjJmuhqGWAetRW47eUjptZ9nyFRRxizg5dt8OzbKGw45qLwTbzvLoo9OWgxJVxJA4zeYFPL74iP47pfv5AFbdDPMvaVerM0c6KuzaZ24Zkpv8CZ24xM8UyRUgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Week 13",
          "title": "Week 13",
          "src": "/static/1fe6a8f966033225a64c83bf36fd45bf/d9199/week-13.png",
          "srcSet": ["/static/1fe6a8f966033225a64c83bf36fd45bf/8ff5a/week-13.png 240w", "/static/1fe6a8f966033225a64c83bf36fd45bf/e85cb/week-13.png 480w", "/static/1fe6a8f966033225a64c83bf36fd45bf/d9199/week-13.png 960w", "/static/1fe6a8f966033225a64c83bf36fd45bf/07a9c/week-13.png 1440w", "/static/1fe6a8f966033225a64c83bf36fd45bf/29114/week-13.png 1920w", "/static/1fe6a8f966033225a64c83bf36fd45bf/97a96/week-13.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Week 8 - Leaderboard</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGklEQVQ4y2WUaU8TURSG+5eMoNBIoZROZ2mnBVqggks0LhCIC9IS40ZV3BJcvmkkEqNBjdHEGMUlMdFocMWNYESjiBuKbKUFCvp478Q2IJM5mTPbc895zztjS0zOMD4xbcXsfHZMpP6QSKWYmiGzTYtIvzM7bGnI7KMEJCVE5Knf8Pb9Rxpqd7N9WxMth4/R3nadJ496SE79nlNMBpiGyQfiySkGh8cYGk3wayTO9B940PmYrZFdtLaeYt/eAzRXXyCyuoX+b9+ZnGY+MNOaAO5s2oNueAmFQgRDZVTX1NJ+7gI7YzHaz1/kasct7j2+z7mL53n6/JW1YDyZykAzQNnm4EiCtauXY3p9FLk1CgqceBSF4ydOoigecu157N8dY+OGzeQXuOi4edvScywxNb9CsfPj5zCV69cQrlqJVy0RUBXD5+XS5SsYhg9V89IYbaC2ro6FWdm0nT5jAUfHJ+dXKCfY9/k74bIQleVVqEoAp8NFKFjMzdt38HhUCgtduAXY8AfIzcll3/6DiJnNb1lekNN80/uBEtMk6A+iayXkLXEQDofpfPQUVdXQdIPy8gpxrRKns5CNm+rFIGfmOMQmEwmUFnsmRNa9JqYvhKb4ceQ5qKmpputlN4pHQ1EN0bZOUZGCW8hRtWwFw8INafvMAYphcfd+J6ZoqdhXhuoxsdtziUYbed3TSzCgsW5VFT5vqajeFBIo+E0fH/u/WXKN/2vballOSQKv3bglqvMTEBUaWqnQKYc9zc28e9+HUuxnVV0toUCYksBSdN0nKlZ5+KTL6i6to00mw2NJy0/SZ4YuPFhaibtIZ1FWFkeOHrOAdqcTLVCM4jLIz1Ow5+STk72Aq9c65ljHJpM08GRrG4uyF6O6TVyFKtkC2HL4CH1fBoht30G9sEt9JEqksZGIkKKpKUZ3z1vLNpIhjxngSHyCrhevhRUOUb8lSkXFUhyOJZw+c9a6/0uYfkg8E0+krCFI3eQPQlYlQbJTGTZ5Ir/bH4MjDAhj/xwa5cOnL3S/6RWf1kv6vw4I4IS1oFxchqXXP8D/f5y//c+jeLPBPKYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Week 8",
          "title": "Week 8",
          "src": "/static/9c73cc003410f5546e29c51972aabb54/d9199/week-8.png",
          "srcSet": ["/static/9c73cc003410f5546e29c51972aabb54/8ff5a/week-8.png 240w", "/static/9c73cc003410f5546e29c51972aabb54/e85cb/week-8.png 480w", "/static/9c73cc003410f5546e29c51972aabb54/d9199/week-8.png 960w", "/static/9c73cc003410f5546e29c51972aabb54/07a9c/week-8.png 1440w", "/static/9c73cc003410f5546e29c51972aabb54/29114/week-8.png 1920w", "/static/9c73cc003410f5546e29c51972aabb54/97a96/week-8.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Rules" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <ProjectBox image="slack-example-icon" title="Join us on Slack" description="Learn, get feedback, share, and more with other designers" link="https://join.slack.com/t/tampabaydesigners/shared_invite/enQtNjkxOTYxMjM2ODU1LWQzNWYyZDhmZWRkNjBlYTdkZGZmMjZiZTU4NTMzMDkxYTFkNmU0ZjEzZjgyYjRkOWQ1YmEzYWIxNjIyMGU5ODU" mdxType="ProjectBox" />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      